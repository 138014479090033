.container {
  width: 100%;
  /* text-align: center; */

  /* padding: 50px; */
  /* margin: 60px; */
  background-color: #f6f6f6;
  padding-block: 2rem;
}

.heading {
  font-weight: bolder;
  font-size: 24px;
  text-align: center;
}

.loader {
  border: 6px solid #f3f3f3; /* Light grey */
  border-top: 6px solid #f07663; /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

.cardOn {
  /* width: 50%; */
  border-left: 15px solid #f26e5f;
  padding: 2%;
  padding-left: 3%;
  padding-right: 6%;
  margin-top: 3%;
}

.cardOff {
  border-left: 15px solid #9d9d9d;
  padding: 2%;
  padding-left: 3%;
  padding-right: 6%;
  margin-top: 3%;
}

.display {
  display: flex;
  justify-content: space-between;
}
.margin {
  margin-top: 3%;
  display: flex;
  justify-content: space-between;
}

.thankyou {
  display: flex;
  margin-top: 5%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.pwaBanner {
  width: 100%;
  position: relative;
}

.imageInput {
  display: none;
}

.label {
  font-size: bold;
  /* margin-bottom: 15px; */
}

.border {
  border-bottom: 1px solid grey;
  width: 100%;
  margin-top: 5%;
  margin-right: 8%;
  align-self: center;
}

.save {
  margin: 30px 0px 0px 0px;
  align-self: center;
  display: flex;
  justify-content: center;
}

.row {
  /* display: flex;
  align-items: center;
  justify-content: center; */
  background-color: white;
  width: 80%;
  padding: 30px;
}

.rowhead {
  text-align: left;
  background-color: white;
  padding: 30px;
  display: flex;
  justify-content: space-between;
  width: 80%;
}

.menus {
  margin-top: 10px;
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
}
.uploadImg {
  margin: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  width: 80%;
}

.colorPicker {
  align-self: flex-start;
}

.pwaBannerText {
  position: absolute;
  top: 10%;
  left: 45%;
  font-size: 24px;
  font-weight: 700;
  color: #000000;
}

.modal {
  /* display: none; Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.modalcontent {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.flexDisplay {
  display: flex;
  justify-content: center;
  align-items: center;
}

.saveHead {
  color: #f2755f;
  font-weight: 700;
  margin-top: 15px;
}

.saveContent {
  font-weight: 600;
  font-size: 20px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.businessSelect {
  display: flex;
  justify-content: flex-end;
  padding-right: 10%;
  margin-top: 15px;
}

.colorSelection {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 50%;
}

.maindiv {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 20px;
  margin: 10px;
}

.headerFooterDiv {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.customContainer {
  display: flex;
  width: 50vw;
}

.permissionsContainer {
  display: flex;
}

.permissionsToggleContainer {
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  width: 100%;
}

.permissionsToggleContainer p {
  text-wrap: nowrap;
}

.headerRow {
  background-color: white;
  width: 80%;
  padding: 15px 30px;
}

.headContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.headContent {
  margin: 0;
  font-size: 15px;
  color: rgba(51, 51, 51, 0.7);
}

.head {
  margin: 0;
  font-size: 18px;
  color: rgba(242, 110, 95, 1);
}
